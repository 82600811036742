
const digest = '66812dd75dab90c95274980bbbff9e8897584b49a5b5bfacc996641620c34ae2';
const css = `._newProjectButton_kmg15_1 {
  border-radius: 10rem;
  border-color: var(--color-brand);
  border: 1px solid var(--color-brand);
  background: var(--color-brand);
  color: var(--color-white);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.5rem 1.5rem;
  transition: all 0.25s var(--transition);
}

._formWrapper_kmg15_15 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1.5rem;
}

._box_kmg15_22,
._boxBundle_kmg15_23 {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 21px 16px;
  margin: 0 0.5rem;
  width: 50%;
}

._boxBundle_kmg15_23 {
  position: relative;
}

._boxContent_kmg15_35 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

._text_kmg15_41,
._textBottom_kmg15_42 {
  font-size: 14px;
  color: #4b5563;
}

._textBottom_kmg15_42 {
  margin-bottom: 0.5rem;
}

._borderLineBundle_kmg15_51 {
  border-top: 1px solid #d1d5db;
  display: block;
  position: absolute;
  bottom: 21px;
  width: 189px;
}

._borderLinePro_kmg15_59 {
  border-top: 1px solid #d1d5db;
  margin-top: 1rem;
  padding: 14px;
}

._example_kmg15_65,
._exampleBundle_kmg15_66 {
  text-align: center;
}

._exampleBundle_kmg15_66 {
  padding: 26px 14px 26px 14px;
}

._exampleBoldText_kmg15_74 {
  font-weight: 600;
}

._exampleText_kmg15_78 {
  font-size: 14px;
  color: #4b5563;
}

._exampleMinimum_kmg15_83 {
  font-size: 12px;
  color: #4b5563;
  margin-top: 8px;
  text-align: center;
}

._button_kmg15_90 {
  width: 100%;
}

._contactModalButton_kmg15_94 {
  width: auto;
}

._button_kmg15_90,
._buttonPurchaseNow_kmg15_99,
._contactModalButton_kmg15_94 {
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
}

._buttonPurchaseNow_kmg15_99 {
  background-color: #fff;
  border: 1px solid #4b5563;
  color: #4b5563;
  width: 100%;
}

._button_kmg15_90,
._contactModalButton_kmg15_94 {
  background-color: #5840e0;
  border: none;
  color: #fff;
  width: 100%;
}

._link_kmg15_126 {
  color: #5840e0;
  font-size: 14px;
  text-decoration: none;
}

._upgradeModal_kmg15_132 {
  display: flex;
  justify-content: space-between;
  margin: 0 -0.5rem;
}

._contactModal_kmg15_94 {
  margin: 0 -0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  color: #4b5563;
  font-size: 14px;
}

._contactModalButton_kmg15_94 {
  margin: 2rem 0 0 auto;
}

._alert_kmg15_149 {
  margin-right: 0.25rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"newProjectButton":"_newProjectButton_kmg15_1","formWrapper":"_formWrapper_kmg15_15","box":"_box_kmg15_22","boxBundle":"_boxBundle_kmg15_23","boxContent":"_boxContent_kmg15_35","text":"_text_kmg15_41","textBottom":"_textBottom_kmg15_42","borderLineBundle":"_borderLineBundle_kmg15_51","borderLinePro":"_borderLinePro_kmg15_59","example":"_example_kmg15_65","exampleBundle":"_exampleBundle_kmg15_66","exampleBoldText":"_exampleBoldText_kmg15_74","exampleText":"_exampleText_kmg15_78","exampleMinimum":"_exampleMinimum_kmg15_83","button":"_button_kmg15_90","contactModalButton":"_contactModalButton_kmg15_94","buttonPurchaseNow":"_buttonPurchaseNow_kmg15_99","link":"_link_kmg15_126","upgradeModal":"_upgradeModal_kmg15_132","contactModal":"_contactModal_kmg15_94","alert":"_alert_kmg15_149"};
export { css, digest };
  